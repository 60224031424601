import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { init as initApm } from "@elastic/apm-rum";

initApm({
  serviceName: "Website",
  serverUrl: "https://apm.quickrecruit.com",
  environment: "Development",
  serviceVersion: "1.0.0"
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
